import service from '@/utils/request'

export const submit = (data) => {
  return service({
    url: '/customer/submit',
    method: 'post',
    data
  })
}

export const questionnaire = (params) => {
  return service({
    url: '/customer/questionnaire',
    method: 'get',
    params
  })
}

export const view = (params) => {
  return service({
    url: '/customer/view',
    method: 'get',
    params
  })
}

export const sms = (params) => {
  return service({
    url: '/customer/sms',
    method: 'get',
    params
  })
}

export const smscheck = (data) => {
  return service({
    url: '/customer/smscheck',
    method: 'post',
    data
  })
}